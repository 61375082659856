import React, { useState } from "react";
import PropTypes from "prop-types";
import SectionTitle from "@components/section-title";
import BlogCard from "@components/blog-card/layout-01";
import { SectionTitleType, BlogType } from "@utils/types";
import { ArrowDown } from "react-feather";
import Button from "@ui/button";
import cn from "classnames";

const BlogArea = ({ data, id }) => {
    const blogPosts = data?.blogs;
    const [length, setLength] = useState(6);

    return (
        <div className="rn-blog-area rn-section-gap section-separator" id={id}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {data?.section_title && (
                            <SectionTitle
                                {...data.section_title}
                                align="center"
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-delay="500"
                                data-aos-once="true"
                            />
                        )}
                    </div>
                </div>
                <div className="row row--25 mt--30 mt_md--10 mt_sm--10">
                    {blogPosts.slice(0, length).map((blog) => (
                        <div
                            key={blog.slug}
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-delay="400"
                            data-aos-once="true"
                            className="col-lg-6 col-xl-4 mt--30 col-md-6 col-sm-12 col-12 mt--30"
                        >
                            <BlogCard
                                title={blog.title}
                                id={blog.id}
                                slug={blog.slug}
                                category={blog.category}
                                image={blog.image}
                                date={blog.date}
                                content={blog.content}
                                readingTime={blog.readingTime}
                            />
                        </div>
                    ))}
                </div>
                {length < blogPosts.length ? (
                    <div className="row row--25 mt--25 mt_md--25 mt_sm--25">
                        <div className="col-lg-12">
                            <div className={cn(`section-title text-center`)}>
                                <Button
                                    type="submit"
                                    id="loadmoreblogs"
                                    onClick={() => setLength(length + 3)}
                                >
                                    <span>LOAD MORE</span>
                                    <ArrowDown />
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

BlogArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        blogs: PropTypes.arrayOf(PropTypes.shape(BlogType)),
    }),
};

BlogArea.defaultProps = {
    id: "blog",
};

export default BlogArea;
