import React, { Children } from "react";
import PropTypes from "prop-types";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import SectionTitle from "@components/section-title";
import { SectionTitleType } from "@utils/types";
import Button from "@ui/button";
import cn from "classnames";
import { Download } from "react-feather";
import { useStaticQuery, graphql } from "gatsby";

const ResumeArea = ({ data, children, id }) => {
    const childArr = Children.toArray(children);

    const pdfData = useStaticQuery(graphql`
        {
            allFile(filter: { sourceInstanceName: { eq: "pdf" } }) {
                edges {
                    node {
                        publicURL
                        name
                    }
                }
            }
        }
    `);

    return (
        <div
            className="rn-resume-area rn-section-gap section-separator"
            id={id}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {data?.section_title && (
                            <SectionTitle
                                align="center"
                                {...data.section_title}
                            />
                        )}
                    </div>
                </div>
                <div className="row mt--45">
                    <div className="col-lg-12">
                        <Tabs
                            defaultActiveKey={childArr?.[1]?.props?.data?.id}
                            className="rn-nav-list"
                        >
                            {childArr.map((item) => (
                                <Tab
                                    key={item.props.data.id}
                                    eventKey={item.props.data.id}
                                    id={item.props.data.id}
                                    title={item.props.data.title}
                                >
                                    {item}
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div>
                <div className="row row--25 mt--25 mt_md--25 mt_sm--25">
                    <div className="col-lg-12">
                        <div className={cn(`section-title text-center`)}>
                            {pdfData.allFile.edges.map((file) => (
                                <Button
                                    id="downloadresume"
                                    type="submit"
                                    key={file.id}
                                >
                                    <a
                                        download={file.node.name}
                                        href={file.node.publicURL}
                                    >
                                        <span>DOWNLOAD  </span>
                                        <Download />
                                    </a>
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ResumeArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
    }),
    children: PropTypes.node.isRequired,
};

ResumeArea.defaultProps = {
    id: "resume",
};

export default ResumeArea;
