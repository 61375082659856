import React, { useState } from "react";
import PropTypes from "prop-types";
import SectionTitle from "@components/section-title";
import Instagram from "@components/instagram";
import { ItemType, SectionTitleType } from "@utils/types";
import { useStaticQuery, graphql } from "gatsby";
import { ArrowDown } from "react-feather";
import Button from "@ui/button";
import cn from "classnames";

const IgTipsArea = ({ data, id }) => {
    const queryData = useStaticQuery(graphql`
        query {
            allInstaNode(
                filter: {
                    id: {
                        nin: [
                            "BEvbAysn7Qw"
                            "_xFvepn7Sk"
                            "_Ebfezn7eB"
                            "s5WqewH7VT"
                            "Cao-fRtqNFe"
                        ]
                    }
                }
                limit: 100
                sort: { fields: [likes], order: [DESC] }
            ) {
                edges {
                    node {
                        id
                        caption
                        likes
                        comments
                        mediaType
                        preview
                        original
                        timestamp
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FIXED
                                    width: 350
                                    height: 350
                                )
                            }
                        }
                    }
                }
            }
        }
    `);

    const instaPosts = queryData.allInstaNode.edges;
    const [length, setLength] = useState(6);
    return (
        <div
            className="rn-portfolio-area rn-section-gap section-separator"
            id={id}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {data?.section_title && (
                            <SectionTitle
                                align="center"
                                {...data.section_title}
                            />
                        )}
                    </div>
                </div>

                <div className="row row--25 mt--10 mt_md--10 mt_sm--10">
                    {instaPosts.slice(0, length).map(({ node }) => (
                        <div
                            key={node.id}
                            data-aos="fade-up"
                            data-aos-delay="100"
                            data-aos-once="true"
                            className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
                        >
                            <Instagram
                                title="" //{node.caption}
                                category="Tips & Tricks"
                                path={`https://www.instagram.com/p/${node.id}`}
                                likeCount={node.likes}
                                image={
                                    node.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                                texts={[{ content: node.caption }]}
                            />
                        </div>
                    ))}
                </div>

                {length < instaPosts.length ? (
                    <div className="row row--25 mt--25 mt_md--25 mt_sm--25">
                        <div className="col-lg-12">
                            <div className={cn(`section-title text-center`)}>
                                <Button
                                    type="submit"
                                    id="loadmoretips"
                                    onClick={() => setLength(length + 3)}
                                >
                                    <span>LOAD MORE</span>
                                    <ArrowDown />
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

IgTipsArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

IgTipsArea.defaultProps = {
    id: "tipstricks",
};

export default IgTipsArea;
